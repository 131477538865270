import React from "react"
import Layout from "../components/layout.component"
import { Navbar } from "../components/navbar.component"
import { RequestQuote } from "../components/request-quote.component"
import { ShippingCalculator } from "../components/shiping-calculator.component"

const ShippingCalculatorDemo = ({}) => {
  return (
    <Layout>
      <Navbar slides={[{}]}/>
      <main className="main_alt" style={{paddingTop: "200px", display: "flex", justifyContent: "center"}}>
        <ShippingCalculator />
      </main>
      <RequestQuote lang={'en-us'}/>
    </Layout>
  )
}

export default ShippingCalculatorDemo;


